.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.space {
  height: 10px;
}

.button  {
  text-align: center;
  align-content: center;
  background-color: #36ac5d;
  color: #fff;
  width: 100px;
  border:none;
  border-radius:10px;

  padding: 25px;
  cursor: pointer;
}
.button:hover {
  background-color:#002ead;
  transition: 0.7s;
}

.button:hover {
  background-color:#002ead;
  transition: 0.7s;
}

.button:hover {
  background-color:#002ead;
  transition: 0.7s;
}

.results-area {
  width: 50%;
  height: 100%;
}


/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
